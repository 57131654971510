<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="updateForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(createPlan)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="plan.name"
                      name="name"
                      rules="required"
                      label="Plan Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="plan.desc"
                      name="name"
                      rules="required"
                      label="Plan Desc."
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="plan.price"
                      name="price"
                      rules="required|min_value:1"
                      label="Price ($)"
                      type="number"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="plan.trial_period_days"
                      rules="numeric"
                      name="trial_period_days"
                      label="Trial period days"
                      type="number"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Interval</label>
                    <select v-model="plan.interval" class="form-control">
                      <option
                        v-for="interval in intervals"
                        :key="interval.key"
                        :value="interval.key"
                        >{{ interval.label }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <label>Description</label>
                    <editor v-model="plan.description" :api-key="tinyMCEKey" :init="{ height: 250 }" :toolbar="customToolbar" :plugins="plugins"/>
                  </div>
                </div>
                <h5 class="h5 mt-3">Features</h5>
                <table class="table table-bordered meta-table">
                  <thead class="thead-light">
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <!-- <th></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in plan.metadata" :key="index">
                      <td>
                        <strong>{{item.key}}</strong>
                      </td>
                      <td>
                        <TextInput
                          v-if="item.key !== 'Visible'"
                          v-model="item.value"
                          name="value"
                        />
                        <b-form-checkbox
                          v-else
                          v-model="item.value"
                          name="check-button"
                          switch
                          inline
                          class="mt-2"
                        >
                        </b-form-checkbox>
                      </td>
                    </tr>
                    <tr v-if="!isSalePlan">
                      <td>
                        <strong>Visible</strong>
                      </td>
                      <td>
                        <b-form-checkbox
                          v-model="plan.visible"
                          name="check-button"
                          switch
                          inline
                          class="mt-2"
                        >
                        </b-form-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingCreate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingCreate" small />
                    <span v-else>Create</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    'editor': Editor
  },

  data() {
    return {
      loadingCreate: false,
      intervals: [
        { key: 'day', label: 'Daily' },
        { key: 'week', label: 'Weekly' },
        { key: 'month', label: 'Monthly' },
        { key: 'quarter', label: 'Every 3 months' },
        { key: 'semiannual', label: 'Every 6 months' },
        { key: 'year', label: 'Yearly' },
      ],
      plan: {
        interval: 'month',
        visible: true,
        metadata: [
          { key: 'Message Limit', value: 0 },
          { key: 'Contact Limit', value: 0 },
        ],
      },
      newFeature: {
        key: '',
        value: '',
      },
      tinyMCEKey: process.env.VUE_APP_TINYMCE_KEY,
      plugins: 'imagetools image autolink link media lists',
      customToolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image media',
    }
  },

  mounted() {
  },

  methods: {
    createPlan() {
      this.loadingCreate = true

      this.$store
        .dispatch('subscription/createBusinessPlan', this.plan)
        .then(() => {
          this.loadingCreate = false
          this.$router.push({ name: 'admin.business-plans.index' })
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },

    addMetadata() {
      this.plan.metadata.push(this.newFeature)
      this.newFeature = { key: '', value: '' }
    },

    removeMetadata(index) {
      this.plan.metadata.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
  .meta-table {
    td, th {
      vertical-align: middle;
    }
    .form-group {
      margin-bottom: 0!important;
    }
  }
</style>